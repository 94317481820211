<template>
  <v-container
    tag="section"
    fluid
  >
    <v-btn
      absolute
      top
      right
      fab
      small
      @click="downloadcsv()"
    >
      <v-icon>
        fa-download
      </v-icon>
    </v-btn>

    <v-data-table
      :items="acls"
      :headers="[
        { text: 'Liga', value: 'league.name', sortable: false },
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        { text: 'Mannschaftsverantwortliche', value: 'mv', sortable: false },
        { text: '', value: 'mvadd', sortable: false },
        { text: 'Verein', value: 'team.club.name', sortable: false },
        { text: 'Vereinsverantwortliche', value: 'vv', sortable: false },
        { text: '', value: 'vvadd', sortable: false },
      ]"
      :items-per-page="-1"
      :sort-by="['league.order','team.name']"
      @click:row="gototeam"
    >
      <template #item.mv="{item}">
        <div
          v-for="p in item.team.acl"
          :key="p._id">
          {{ p | person }}
          <v-btn
            fab
            x-small
            text
            @click.stop="del(p._id, item.team._id, 'team')"
          >
            <v-icon style="font-size:150%;">far fa-trash-alt</v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.mvadd="{item}">
        <v-btn
          fab
          small
          text
          @click.stop="openadd(item.team._id, 'team', item.team.name)"
        >
          <v-icon>fa-plus</v-icon>
        </v-btn>
      </template>
      <template #item.vv="{item}">
        <div
          v-for="p in item.team.club.acl"
          :key="p._id"
        >
          {{ p | person }}
          <v-btn
            fab
            x-small
            text
            @click.stop="del(p._id, item.team.club._id, 'club')"
          >
            <v-icon style="font-size:150%;">far fa-trash-alt</v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.vvadd="{item}">
        <v-btn
          fab
          small
          text
          @click.stop="openadd(item.team.club._id, 'club', item.team.club.name)"
        >
          <v-icon>fa-plus</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <base-material-dialog
      :value="add.open"
      icon="far fa-user-friends"
      :title="`${add.type === 'club' ? 'Verein' : 'Mannschaft'}svertreter für ${add.name} hinzufügen`"
      color="primary"
      :actions="[ 'save', 'close' ]"
      @close="closeadd"
      @esc="closeadd"
      @save="saveadd"
    >
      <base-edit-dialog-personsuche
        :value="add.fetch | person"
        :label="`${add.type === 'club' ? 'Verein' : 'Mannschaft'}svertreter`"
        id="null"
        clearable
        @input="({value}) => { add.fetch = null; add.p = value }"
      />
    </base-material-dialog>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  ... on StbW2023 {
    leagues {
      _id
      name
      order
      teams {
        team {
          _id
          name
          acl(gender: w) { _id givenName familyName email { value } }
          club {
            _id
            name
            acl(gender: w) { _id givenName familyName email { value } }
          }
        }
      }
    }
  }
`

export default {
  name: 'personenverantwortliche',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: {},
    add: {
      open: false,
      type: '',
      id: null,
      name: '',
      p: null,
      fetch: null
    }
  }),

  computed: {
    acls () {
      return this.Event.leagues?.reduce((acc, curr) => {
        acc.push(...(curr.teams || []).map(t => ({
          ...t,
          league: curr
        })))

        return acc
      }, []) || []
    },
    person () {
      return this.add.fetch
    }
  },

  methods: {
    async del (id, aclid, type) {
      await this.mutate({
        mutation: gql`
          mutation($id: UUID!, $type: AclType!, $aclid: UUID!) {
            PersonUpdateAclDelete(id: $id, type: $type, aclid: $aclid, gender: w) { _id }
          }
        `,
        variables: {
          id,
          type,
          aclid
        }
      })

      const result = await this.query({
        query: gql`
              query($id: UUID!) { Event(id: $id) { ${query} }}
            `,
        variables: {
          id: this.id
        }
      })

      this.Event = result.Event
    },
    openadd (id, type, name) {
      this.add.open = true
      this.add.id = id
      this.add.type = type
      this.add.p = null
      this.add.name = name
      this.add.fetch = null
    },
    closeadd () {
      this.add.open = false
      this.add.id = null
      this.add.type = ''
      this.add.p = null
      this.add.name = ''
      this.add.fetch = null
    },
    async saveadd () {
      await this.mutate({
        mutation: gql`
          mutation($id: UUID!, $type: AclType!, $aclid: UUID!) {
            PersonUpdateAclAdd(id: $id, type: $type, aclid: $aclid, gender: w) { _id }
          }
        `,
        variables: {
          id: this.add.p,
          type: this.add.type,
          aclid: this.add.id
        }
      })

      this.closeadd()

      const result = await this.query({
        query: gql`
          query($id: UUID!) { Event(id: $id) { ${query} }}
        `,
        variables: {
          id: this.id
        }
      })

      this.Event = result.Event
    },
    downloadcsv () {
      let maxemails = 0
      const tmp = this.Event?.leagues.reduce((lacc, lcurr) => {
        lacc.push(...(lcurr.teams || []).reduce((tacc, tcurr) => {
          tacc.push(...(tcurr.team?.acl || []).map(a => ({
            familyName: a.familyName,
            givenName: a.givenName,
            type: 'mv',
            club: tcurr.team.club.name,
            team: tcurr.team.name,
            league: lcurr.name,
            email: a.email?.map(e => e.value) || []
          })))
          tacc.push(...(tcurr.team?.club?.acl || []).map(a => ({
            familyName: a.familyName,
            givenName: a.givenName,
            type: 'vv',
            club: tcurr.team.club.name,
            team: '',
            league: lcurr.name,
            email: a.email?.map(e => e.value) || []
          })))
          return tacc
        }, []))

        const max = lacc.reduce((a, c) => a < c.email.length ? c.email.length : a, 0)

        maxemails = maxemails < max ? max : maxemails

        return lacc
      }, []).map((e) => {
        return [
          e.league,
          e.type,
          e.club,
          e.team,
          e.givenName,
          e.familyName,
          e.email.join(';')
        ].join(';')
      })

      const csv = `Liga;Typ;Verein;Mannschaft;Vorname;Nachname;${Array(maxemails).fill('').map((v, i) => `E-Mail ${i + 1}`).join(';')}\n${tmp.join('\n')}`

      const saveByteArray = (fileName, bytes) => {
        const blob = new Blob(['\ufeff', bytes], { type: 'application/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        link.click()
      }

      saveByteArray('Verantwortliche.csv', csv)
    },
    gototeam (item) {
      this.$router.push(`/e/${this.id}/team/${item.team._id}/turner`)
    }
  },

  watch: {
    add: {
      deep: true,
      handler (data) {
        if (data.p && !data.fetch) {
          this.query({
            query: gql`
              query($id: UUID!) {
                Person(id: $id) { _id givenName familyName }
              }
            `,
            variables: {
              id: this.add.p
            }
          }).then((data) => {
            this.add.fetch = data.Person
          })
        }
      }
    }
  },

  apollo: {
    Event: {
      query: gql`
        query($id: UUID!) { Event(id: $id) { ${query} }}
      `,
      variables () {
        return {
          id: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($id: UUID!) { EventUpdate(id: $id) { ${query} }}
        `,
        variables () {
          return {
            id: this.id
          }
        }
      }
    }
  }
}

</script>

<style scoped>

</style>
